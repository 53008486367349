import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import HolyWeeKCalendar from "../../../static/pdf/resources/Holy_Week_calendar_2020.pdf" 
import HolyWeekSchedlue from "../../../static/pdf/resources/Special_Holy_Week_Schedule_2020.pdf" 
import Letter from "../../../static/pdf/resources/Letter_to_St_John_s_for_Holy_Week_4-8-20.pdf"
import MaryOfEgypt from "../../../static/pdf/resources/The_Life_of_our-Holy_Mother_Mary_of_Egypt.pdf"
import LiturgicalExplination from "../../../static/pdf/resources/A-Liturgical-Explanation-of-Holy-Week.pdf"
import LazarusHymmSheet from "../../../static/pdf/resources/Lazarus_Saturday_Hymn__Sheet_Apr_11,_2020_.pdf"
import PalmSundayHymmSheet from "../../../static/pdf/resources/Palm_Sunday_Hymn__SheetApr_12,_2020_.pdf"
import FridayBeforeLazarusCompline from "../../../static/pdf/resources/Friday_before_Lazarus_Saturday_-_Little_Comline.pdf"
import SaturdayBeforePalmSunday from "../../../static/pdf/resources/Home Vespers - Palm Sunday.pdf"
import Atrium from "../../../static/pdf/resources/Atrium_Holy_Week_for_Children.pdf"

import BridegroomMatinsSunday from "../../../static/pdf/resources/Bridegroom_Matins_-_Sunday_Night_2020.pdf"
import BridegroomMatinsMonday from "../../../static/pdf/resources/Bridegroom_Matins-_Monday_Night.pdf"
import BridegroomMatinsTuesday from "../../../static/pdf/resources/Bridegroom_Matins_-_Tuesday_Night.pdf"
import WednesdayNight from "../../../static/pdf/resources/Home Vespers - Holy Wednesday.pdf"

import FridayHours from "../../../static/pdf/resources/Holy_Friday_-_Royal_Hours,_Sixth_Hour.pdf"

import Holy_Thursday_Vesperal_Liturgy_Hymn__Sheet  from "../../../static/pdf/resources/Holy_Thursday_Vesperal_Liturgy_Hymn__Sheet.pdf"
import Holy_Friday_Great_Vespers_Hymn__Sheet from "../../../static/pdf/resources/Holy_Friday_Great_Vespers_Hymn__Sheet.pdf"
import Holy_Friday_Matins_with_12_Gospel_Readings from "../../../static/pdf/resources/Holy_Friday_Matins_with_12_Gospel_Readings.pdf"
import Holy_Saturday_Vesperal_Liturgy_Hymn_Sheet from "../../../static/pdf/resources/Holy_Saturday_Vesperal_Liturgy_Hymn_Sheet.pdf"
import Holy_Saturday_Matins_with_Lamentations from "../../../static/pdf/resources/Holy_Saturday_Matins_with_Lamentations.pdf"


import Pascha_Matins_and_Liturgy_430am from "../../../static/pdf/resources/Pascha_Matins_and_Liturgy_-_430am.pdf"
import Agape_Vespers_Sunday_315pm from "../../../static/pdf/resources/Agape_Vespers__-_Sunday_315pm.pdf"

import Bright_Week_Hours from "../../../static/pdf/resources/Bright_Week_Hours.pdf"

import Saint_Thomas_Vespers from "../../../static/pdf/resources/Saturday Night Vespers for St Thomas Texts 2020.pdf"
import Saint_Thomas_Liturgy from "../../../static/pdf/resources/St Thomas Sunday Matins and LIturgy Texts 2020.pdf"

import ArchivedResources from "../../components/resources/ArchivedResources"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/resources.css"
import "../../styles/header.css"


function Resources () {
	
	const exclude_dirs = [ 'archived', 'no catagory', 'av files']

	const query = graphql`
	      	      query {
  dirs: allDirectory(filter: {relativeDirectory: {eq: "resources"}}, sort: {order: DESC, fields: modifiedTime}) {
    edges {
      node {
        id
        relativePath
        name
      }
    }
  }
    archived: allDirectory(filter: {relativeDirectory: {eq: "resources/archived"}}, sort: {order: DESC, fields: modifiedTime}) {
    edges {
      node {
        id
        relativePath
        name
      }
    }
  }
  allFile(filter: {relativeDirectory: {regex: "/^resources/"}}, sort: {order: DESC, fields: modifiedTime}){
    edges {
      node {
        id
        relativeDirectory
        name
        publicURL
      }
    }
  }
}
	`


	const data = useStaticQuery(query)

	let resourceFiles = data.allFile.edges;

	let dirs = data.dirs.edges.map((item, key) => {

			if(!exclude_dirs.includes(item.node.name)){
				const files = resourceFiles.filter(element => element.node.relativeDirectory == item.node.relativePath)
				const filesJSX = files.map((file,key2) => <li key={key2} className="resource-file"><a href={file.node.publicURL}  target="_blank">{file.node.name.replace(/_/g," ")}</a></li>)
				return (
					<li key={key}>
						<div className="resource-list-header">{item.node.name}</div>
						<ul>{filesJSX}</ul>
					</li>
				)
			} else {
				return null;
			}
		})




	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content resources">
				<div className='subheader'>
					<h2 className='name'>Service Resources</h2>
				</div>
				<ul className = "resources-dirs">
					{dirs}
					<li>
						<div className="resource-list-header">Audio Files – Music and Recordings</div>
						<ul>
							<li className="resource-file"><a href="/resources/st-mary-of-egypt" target="_blank">5th Thursday of Lent – Life of Saint Mary of Egypt by Jennifer Medders and Stan Snider</a></li>
							<li className="resource-file"><a href="/resources/rejoice-o-bethany" target="_blank">Lazarus Saturday – “Rejoice, O Bethany” – Michael and Savannah Tallino-Smith</a></li>
							<li className="resource-file"><a href="/resources/st_kassiani" target="_blank">Holy Wednesday – “Hymm to Saint Kassiani” – Elisa Betson, Jessica Kies, and Cara Fletes</a></li>
							<li className="resource-file"><a href="/resources/the-wise-thief" target="_blank">Holy Friday Matins - "The Wise Thief" – Elisa Betson, Jessica Kies, and Cara Fletes</a></li>
							<li className="resource-file"><a href="/resources/the-soldiers-guarding" target="_blank">Holy Saturday Matins - "The Soldier Guarding Your Tomb" – Elisa Betson, Jessica Kies, and Cara Fletes</a></li>
							<li className="resource-file"><a href="/resources/bless-joseph" target="_blank">"Come Let Us Bless Joseph" – Dmitry Bortniansky</a></li>
							<li className="resource-file"><a href="/resources/congregational-singing" >Congregational Singing Of Saint John Orthodox Cathedral</a></li>

				
							<li className="resource-file"><a href="/resources/in-the-flesh" target="_blank">Pascha Exapostilarion - "In The Flesh You Fell Asleep" – Elisa Betson, Jessica Kies, and Cara Fletes</a></li>
						</ul>


					</li>
					<ArchivedResources archived={data.archived} exclude_dirs={exclude_dirs} resourceFiles={resourceFiles}  />

					

				</ul>


			</main>
			<Footer />		
		</div>
		)
}

export default Resources 
//<iframe width="560" height="315" src="https://www.youtube.com/embed/RPWdmb1BF0Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
