import React, { Component } from "react"
import { Link } from "gatsby"
import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import HomeBody from "../../components/home/HomeBody"
import Footer from "../../components/footer/Footer"
import AddCircleOutlineIcon from '@material-ui/icons/Add';
import RemoveCircleOutline from '@material-ui/icons/Remove';
import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/donate.css"
import ReactModal from "react-modal"
import Img from "gatsby-image"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



class PhotoAlbums extends Component {
	constructor(props){
		super(props)

		console.log(props.archived.edges.length)
		console.log(Array(props.archived.edges.length).fill(true))


		this.state = {
			colapseList: true,
			resourceColapsed: Array(props.archived.edges.length).fill(true)
		}
	}
	toggleAll = () => {
		this.setState({
			colapseList: !this.state.colapseList
		})
	}

	toggleList = (key) => {
		console.log(key)
		this.state.resourceColapsed[key] = !this.state.resourceColapsed[key]
		this.setState({
			resourceColapsed: this.state.resourceColapsed
		})
	}

	render() {
			let archived = this.props.archived.edges.map((item, key) => {
			console.log(this.state.resourceColapsed[key])

			const files = this.props.resourceFiles.filter(element => element.node.relativeDirectory == item.node.relativePath)
				const filesJSX = files.map((file,key2) => <li key={key2}><a href={file.node.publicURL}  target="_blank">{file.node.name.replace(/_/g," ")}</a></li>)

			if(!this.props.exclude_dirs.includes(item.node.name)){
				return (
					<li className="archived-resource" key={key} >
						<div onClick={ (event) => this.toggleList(key) }>
							<span className={this.state.resourceColapsed[key] ? 'resource-toggle':'resource-toggle hidden'}><AddCircleOutlineIcon /> </span>
							<span className={this.state.resourceColapsed[key] ? 'resource-toggle hidden':'resource-toggle'}><RemoveCircleOutline  /> </span>

							{item.node.name}
						</div>
						<ul className={this.state.resourceColapsed[key] ? 'hidden':''} >{filesJSX}</ul>
					</li>
				)
			} else {
				return null;
			}
		})


		return(
			<li className="archived-resource-list">
				<span className="toggle-all-resources" onClick={ (event) => this.toggleAll()}>
					<span className={this.state.colapseList ? 'resource-toggle':'hidden resource-toggle'}  >
						<AddCircleOutlineIcon />
					</span>
					<span className={this.state.colapseList ? 'hidden resource-toggle':'resource-toggle'} >
						<RemoveCircleOutline />
					</span>
					Archived Resources
				</span>
				
				<ul className={this.state.colapseList ? 'hidden':''}>
					{archived}
				</ul>
			</li>


		)
	}
	
	

}
export default PhotoAlbums 